import axios from 'axios'
import store from '@/store'
import router from '@/router'
import utils from '@/services/utils'

const baseURL = `${window.location.protocol}//${process.env.VUE_APP_API_URL_DTOUCH}/v1`

const service = {
  checkAvailabilityError (error) {
    if (error.data.status === 'KO') {
      alert (error.data.message ? utils.getTranslation(error.data.message, 'es') : 'Fuera de horario.') 
      return true
    } else return false
  },
  prepareError () {
    alert('error')
  },
  getParamByName(name, url = window.location.href) {
    // eslint-disable-next-line no-useless-escape
    name = name.replace(/[\[\]]/g, '\\$&')  // eslint-disable-line no-use-before-define
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url)
    if (!results) return null
    if (!results[2]) return ''
    return decodeURIComponent(results[2].replace(/\+/g, ' '))
  },
  preparePoint () {
    const { point } = router.currentRoute.query
    if (point) {
      this.getToken()
      return
    } else this.checkToken()
  },
  checkOrder (id) {
    const workspaceID = store.state.app.dtouchWorkspace
    const url = `${baseURL}/private/dish-orders/${workspaceID}/dish-orders/${id}/check?token=${localStorage.getItem('jwt')}`

    return axios.put(url)
      .then(response => {
        return response.data
      })
      .catch(error => {
        if (error.response.status === 403) this.setPointToken()
        return error
      })
  },
  checkToken () {
    const workspaceID = store.state.app.dtouchWorkspace
    const token = this.getPointToken()
    if (token)
      axios.get(`${baseURL}/public/dish-orders/${workspaceID}/check-token/${token}/`)
        .then(response => {
          if (!response) return
          this.setPointToken(token, response.data.decoded.point, response.data.config)
          if (response.data.decoded.point === 'undefined') this.setPointToken()
        })
        .catch(error => {
          if (error.response.status === 403) this.setPointToken()
          console.log('jhm', error.response.status)
        })
  },

  async getToken () {
    const workspaceID = store.state.app.dtouchWorkspace
    const point = this.getParamByName('point')
    await new Promise((resolve) => axios.get(`${baseURL}/public/dish-orders/${workspaceID}/token/${point}/`)
      .then(response => {
        if (!response) return
        const {token, config} = response.data
        this.setPointToken(token, point, config)
        resolve(true)
      })
      .catch(error => {
        this.setPointToken()
        console.log('jhm-error', error)
        resolve(false)
      })
      .finally(() => {
        const query = Object.assign({}, router.currentRoute.query)
        delete query.point
        router.replace({ query })
        router.push({ path: '/point', query })
        resolve(true)
      }))
  },
  /*
  getToken () {
    const workspaceID = store.state.app.dtouchWorkspace
    const point = this.getParamByName('point')
    axios.get(`${baseURL}/public/dish-orders/${workspaceID}/token/${point}/`)
      .then(response => {
        if (!response) return
        const {token, config} = response.data
        this.setPointToken(token, point, config)
      })
      .catch(error => {
        this.setPointToken()
        console.log('jhm-error', error)
      })
      .finally(() => {
        const query = Object.assign({}, router.currentRoute.query)
        delete query.point
        router.replace({ query })
      })
  },*/
  setPointToken (v, point, config) {
    if (!v) {
      store.state.app.dtouchDishOrder = null 
      sessionStorage.setItem('dishOrderToken', '')
      sessionStorage.removeItem('dishOrderToken')
      return 
    }
    if (store.state.app.dtouchDishOrder) {
      store.state.app.dtouchDishOrder.token = v
      store.state.app.dtouchDishOrder.point = point
      store.state.app.dtouchDishOrder.config = config
      store.state.app.dtouchDishOrder.OrderID = this.getOrderSession()
      store.state.app.dtouchDishOrder.LMD = new Date()

    }
    else store.state.app.dtouchDishOrder = {
      token: v,
      point,
      config,
      OrderID: this.getOrderSession(),
      LMD: new Date(),
    }
    sessionStorage.setItem('dishOrderToken', v)
  },
  getPointToken () {
    const aux = sessionStorage.getItem('dishOrderToken')
    return aux && aux.length > 0 ? aux : null
  },

  getOrderSession () {
    const aux = localStorage.getItem('dishOrderID')
    return aux && aux.length > 0 ? aux : null
  },
  setOrderSession (v) {
    localStorage.setItem('dishOrderID', v)
  },

  //get public order list
  getAllOrderItemByOrderID () {
    const workspaceID = store.state.app.dtouchWorkspace
    const orderID = store.state.app.dtouchDishOrder.OrderID
    const token = this.getPointToken()
    const url = `${baseURL}/specific/dish-orders/${workspaceID}/dish-orders/${orderID}/items/?token=${token}`

    return axios.get(url)
      .then(response => {
        return response.data
      })
      .catch(error => {
        if (error.response.status === 403) this.setPointToken()
        return error
      })
  },

  addOrder (v) {
    const token = this.getPointToken()
    const workspaceID = store.state.app.dtouchWorkspace
    return axios.post(`${baseURL}/specific/dish-orders/${workspaceID}/dish-orders/?token=${token}`, v)
      .then(response => {
        if (this.checkAvailabilityError(response)) return
        return response
      })
      .catch(error => {
        if (error.response.status === 403) this.setPointToken()
        return error
      })
  },
  deleteOrderItem (orderItemID, area = 'specific') {
    const workspaceID = store.state.app.dtouchWorkspace
    const token = this.getPointToken()
    let url = `${baseURL}/${area}/dish-orders/${workspaceID}/dish-orders-item/${orderItemID}`
    if (area === 'specific') url += `?token=${token}`

    return axios.delete(url)
      .then(response => {
        return response
      })
      .catch(error => {
        if (error.response.status === 403) this.setPointToken()
        return error
      })
  },
  deleteOrder (v, area = 'specific') {
    const workspaceID = store.state.app.dtouchWorkspace
    const orderID = v ? v : store.state.app.dtouchDishOrder.OrderID
    const token = this.getPointToken()
    let url = `${baseURL}/${area}/dish-orders/${workspaceID}/dish-orders/${orderID}`
    if (area === 'specific') url += `?token=${token}`

    return axios.delete(url)
      .then(response => {
        return response
      })
      .catch(error => {
        if (error.response.status === 403) this.setPointToken()
        return error
      })
  },
  payOrder (body) {
    const workspaceID = store.state.app.dtouchWorkspace
    const orderID = store.state.app.dtouchDishOrder.OrderID
    const token = this.getPointToken()
    let url = `${baseURL}/specific/dish-orders/${workspaceID}/dish-orders/${orderID}/pay-order?token=${token}`
    return axios.put(url, body)
      .then(response => {
        if (this.checkAvailabilityError(response)) return
      })
      .catch(error => {
        this.checkAvailabilityError (error) 
      })
  },
  confirmOrder (area = 'specific', body) {
    const workspaceID = store.state.app.dtouchWorkspace
    const orderID = store.state.app.dtouchDishOrder.OrderID
    const token = this.getPointToken()
    let url = `${baseURL}/${area}/dish-orders/${workspaceID}/dish-orders/${orderID}/confirm`
    if (area === 'specific') url += `?token=${token}`

    return axios.put(url, body)
      .then(response => {
        if (this.checkAvailabilityError(response)) return
        else return response.data
      })
      .catch(error => {
        if (error.response.status === 403) this.setPointToken()
        else if(error.response.status === 406) return { KO: true, error, message: 'Payment required'  }
      })
  },

  preparePayOrder (body) {
    const workspaceID = store.state.app.dtouchWorkspace
    const orderID = store.state.app.dtouchDishOrder.OrderID
    const token = this.getPointToken()
    let url = `${baseURL}/specific/dish-orders/${workspaceID}/dish-orders/${orderID}/prepare-pay?token=${token}`
    return axios.put(url, body)
      .then(response => { 
        if (this.checkAvailabilityError(response)) return
        const url = response.data
//        if (url && url.length > 0) window.open(url, '_blank')

        if (url && url.length > 0) window.open(url, "_self")
          //window.location = url
        else alert('error')
      })
      .catch(error => {
        this.checkAvailabilityError (error) 
        alert(error.response.data)
      })
  },
  getIsPayment () {
    const workspaceID = store.state.app.dtouchWorkspace
    const token = this.getPointToken()
    const url = `${baseURL}/specific/dish-orders/${workspaceID}/dish-orders/is-payment/?token=${token}`
    return axios.get(url)
      .then(response => {
        return response.data && response.data.length > 0 ? true : false
      })
      .catch((error) => {
        if (!error.response || error.response.status === 403) window.location.href = '/#/login'
        return false
      }) 
  },

  getConfig (wID) {
    const workspaceID = wID ? wID : store.state.app.dtouchWorkspace
    const url = `${baseURL}/private/dish-orders/${workspaceID}/dish-orders-config/`
    return axios.get(url)
      .then(response => response.data && response.data.length > 0 ? response.data[0] : null)
      .catch((error) => {
        if (!error.response || error.response.status === 403) window.location.href = '/#/login'
      }) 
  },

  getAllPoints (startIndex = 0, itemPerPage = 5, searchValue = '') {
    const workspaceID = store.state.app.dtouchWorkspace
    const url = `${baseURL}/private/dish-orders/${workspaceID}/dish-orders-points/`.concat(`?&start_index=${startIndex}&item_per_page=${itemPerPage}&search_value=${searchValue}`)
    return axios.get(url)
      .then(response => response.data)
      .catch((error) => {
        if (!error.response || error.response.status === 403) window.location.href = '/#/login'
      }) 
  },

  getPointByID (id) {
    const workspaceID = store.state.app.dtouchWorkspace
    const url = `${baseURL}/private/dish-orders/${workspaceID}/dish-orders-points/${id}`
    return axios.get(url)
      .then(response => response.data && response.data.length > 0 ? response.data[0] : null)
      .catch((error) => {
        if (!error.response || error.response.status === 403) window.location.href = '/#/login'
      }) 
  },





  savePoint (v) {
    const workspaceID = store.state.app.dtouchWorkspace
    let url = `${baseURL}/private/dish-orders/${workspaceID}/dish-orders-points/`
    if (v.ID === 'new') {
      return axios.post(url, v)
        .then(response => response.data)
        .catch((error) => {
          if (!error.response || error.response.status === 403) window.location.href = '/#/login'
        }) 
    } else {
      url += `${v.ID}`
      return axios.patch(url, v)
        .then(response => response.data)
        .catch((error) => {
          if (!error.response || error.response.status === 403) window.location.href = '/#/login'
        }) 
    }
  },
  deletePoint (v) {
    const workspaceID = store.state.app.dtouchWorkspace
    const url = `${baseURL}/private/dish-orders/${workspaceID}/dish-orders-points/${v}`
    return axios.delete(url)
      .then(response => response.data)
      .catch((error) => {
        if (!error.response || error.response.status === 403) window.location.href = '/#/login'
      }) 
  },
  getAllOrdersFiltered (startIndex, itemPerPage, point, status) {
    const workspaceID = store.state.app.dtouchWorkspace
    const url = `${baseURL}/private/dish-orders/${workspaceID}/dish-orders/`
    const body = {
      startIndex, 
      itemPerPage,
      status,
      point
    }
    return axios.put(url, body)
      .then(response => response)
      .catch((error) => {
        if (!error.response || error.response.status === 403) window.location.href = '/#/login'
      }) 
  },


  closeOrder (orderID) {
    const workspaceID = store.state.app.dtouchWorkspace
    const url = `${baseURL}/private/dish-orders/${workspaceID}/dish-orders/${orderID}/close`
    return axios.put(url)
      .then(response => response)
      .catch((error) => {
        if (!error.response || error.response.status === 403) window.location.href = '/#/login'
      }) 
  },
  finalizeOrder (orderID) {
    const workspaceID = store.state.app.dtouchWorkspace
    const url = `${baseURL}/private/dish-orders/${workspaceID}/dish-orders/${orderID}/finalize`
    return axios.put(url)
      .then(response => response)
      .catch((error) => {
        if (!error.response || error.response.status === 403) window.location.href = '/#/login'
      }) 
  },

/*
  getAllOrders (workspaceID, startIndex = 0, itemPerPage = 5, searchValue = '') {
    const url = `v1/private/workspaces/${workspaceID}/dish-orders/`
    return api.getAll('dtouch', url, startIndex, itemPerPage, searchValue)
  },
  */


  // EXTRAS
  getExtras (v) {
    const workspaceID = store.state.app.dtouchWorkspace
    const url = `${baseURL}/public/dish-orders/${workspaceID}/extras/${v}`
    return axios.get(url)
      .then(response => response.data)
      .catch((error) => {
        if (!error.response || error.response.status === 403) window.location.href = '/#/login'
      }) 
  },
  crudExtras (v, body) {
    const workspaceID = store.state.app.dtouchWorkspace
    const url = `${baseURL}/private/dish-orders/${workspaceID}/extras/${v}`
    return axios.put(url, body)
      .then(response => response.data)
      .catch((error) => {
        if (!error.response || error.response.status === 403) window.location.href = '/#/login'
      }) 
  },
  async getUserPermission () {
    const workspaceID = store.state.app.dtouchWorkspace
    return await new Promise((resolve) =>axios.get(`${baseURL}/internal/workspaces/${workspaceID}/user-config/`)
      .then((response) => {
        const aux = response.data.map(item => item.HideDishOrderPoint).shift()
        resolve(aux && aux === 1 ? true : false)
      })
      .catch((error) => {
        console.log(error)
        resolve(false)
      }))
  },
}

export default service

